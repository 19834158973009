import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Layout from '../../components/Layout';
import ServiceForm from './ServiceForm';
import Loading from '../../components/Loading';

const ServicesList = () => {
    const [services, setServices] = useState([]);
    const [showForm, setShowForm] = useState(false);
    const [editService, setEditService] = useState(null);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        fetchServices();
    }, []);

    const fetchServices = async () => {
        setLoading(true);
        try {
            const response = await axios.get('https://api.discoun3ree.com/api/services');
            setServices(response.data);
        } catch (error) {
            console.error('Failed to fetch services', error);
        } finally {
            setLoading(false);
        }
    };

    const handleFormSuccess = () => {
        setShowForm(false);
        fetchServices();
    };

    return (
        <Layout>
            {loading && <Loading />}
            <div className="container mx-auto p-4">
                <div className="flex items-center justify-between w-full">
                    <h1 className="text-2xl font-bold mb-4">Services</h1>
                    <button
                        onClick={() => setShowForm(true)}
                        className="mb-4 bg-primary text-white font-bold py-1.5 px-4 rounded-md focus:outline-none focus:shadow-outline"
                    >
                        Add Service
                    </button>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                    {services.map((service) => (
                        <div
                            key={service.id}
                            className="p-4 bg-white rounded shadow-md cursor-pointer"
                            onClick={() => navigate(`/services/${service.id}`)}
                        >
                            <div className="relative w-full h-24 overflow-hidden">
                                <img
                                    src={service.image_url}
                                    alt=""
                                    className="absolute inset-0 w-full h-full object-cover rounded-md"
                                />
                            </div>
                            <h2 className="text-xl font-semibold">{service.name}</h2>
                            <p className='truncate-2-lines'>{service.description}</p>
                        </div>
                    ))}
                </div>
                {showForm && (
                    <ServiceForm
                        onClose={() => setShowForm(false)}
                        onSuccess={handleFormSuccess}
                        service={editService}
                    />
                )}
            </div>
        </Layout>
    );
};

export default ServicesList;
