import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaSpinner } from 'react-icons/fa';

const ServiceOffers = ({ serviceId, onClose }) => {
    const [offers, setOffers] = useState([]);
    const [formOpen, setFormOpen] = useState(false);
    const [editOffer, setEditOffer] = useState(null);
    const [loading, setLoading] = useState(false);
    const [offerData, setOfferData] = useState({
        start_date: '',
        end_date: '',
        discount: '',
        service_id: serviceId,
    });

    useEffect(() => {
        fetchOffers();
    }, [serviceId]);

    const fetchOffers = async () => {
        try {
            const response = await axios.get(`https://api.discoun3ree.com/api/services/${serviceId}/offers`);
            setOffers(response.data);
        } catch (error) {
            console.error('Failed to fetch offers', error);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setOfferData({
            ...offerData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            if (editOffer) {
                await axios.put(`https://api.discoun3ree.com/api/offers/${editOffer.id}`, offerData);
            } else {
                await axios.post(`https://api.discoun3ree.com/api/offers`, offerData);
            }
            fetchOffers();
            setFormOpen(false);
            setEditOffer(null);
            setOfferData({
                start_date: '',
                end_date: '',
                discount: '',
            });
        } catch (error) {
            console.error('Failed to save offer', error);
        } finally {
            setLoading(false);
        }
    };

    const handleEditClick = (offer) => {
        setEditOffer(offer);
        setOfferData({
            start_date: offer.start_date,
            end_date: offer.end_date,
            discount: offer.discount,
        });
        setFormOpen(true);
    };

    const handleDeleteClick = async (offerId) => {
        try {
            await axios.delete(`https://api.discoun3ree.com/api/offers/${offerId}`);
            fetchOffers();
        } catch (error) {
            console.error('Failed to delete offer', error);
        }
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white max-h-[90vh] overflow-y-auto p-6 rounded-lg w-full max-w-lg">
                <div className="flex items-center w-full justify-between ">
                    <h2 className="text-xl mb-4">Offers for Service</h2>
                    <button
                        onClick={() => setFormOpen(true)}
                        className="bg-primary text-white font-bold py-1.5 px-4 rounded-md focus:outline-none focus:shadow-outline mb-4"
                    >
                        Add Offer
                    </button>
                </div>
                <div className="space-y-4">
                    {offers.map((offer) => (
                        <div key={offer.id} className="p-4 bg-gray-100 rounded-md shadow-md">
                            <p>Start Date: {new Date(offer.start_date).toLocaleDateString()}</p>
                            <p>End Date: {new Date(offer.end_date).toLocaleDateString()}</p>
                            <p>Discount: {offer.discount}</p>
                            <div className="flex justify-end space-x-2 mt-2">
                                <button
                                    onClick={() => handleEditClick(offer)}
                                    className="bg-blue-500 text-white py-1 px-2 rounded"
                                >
                                    Edit
                                </button>
                                <button
                                    onClick={() => handleDeleteClick(offer.id)}
                                    className="bg-red-500 text-white py-1 px-2 rounded"
                                >
                                    Delete
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
                {formOpen && (
                    <form onSubmit={handleSubmit} className="mt-4 space-y-4">
                        <div>
                            <label className="block text-gray-700">Start Date</label>
                            <input
                                type="date"
                                name="start_date"
                                value={offerData.start_date}
                                onChange={handleInputChange}
                                className="p-2 border rounded w-full"
                                required
                            />
                        </div>
                        <div>
                            <label className="block text-gray-700">End Date</label>
                            <input
                                type="date"
                                name="end_date"
                                value={offerData.end_date}
                                onChange={handleInputChange}
                                className="p-2 border rounded w-full"
                                required
                            />
                        </div>
                        <div>
                            <label className="block text-gray-700">Discount</label>
                            <input
                                type="number"
                                name="discount"
                                value={offerData.discount}
                                onChange={handleInputChange}
                                className="p-2 border rounded w-full"
                                required
                            />
                        </div>
                        <div className="flex justify-end space-x-2">
                            <button
                                type="button"
                                onClick={() => {
                                    setFormOpen(false);
                                    setEditOffer(null);
                                    setOfferData({
                                        start_date: '',
                                        end_date: '',
                                        discount: '',
                                    });
                                }}
                                className="bg-gray-500 text-white py-2 px-4 rounded"
                            >
                                Cancel
                            </button>
                            <button
                                type="submit"
                                className={`bg-primary text-white py-2 px-4 rounded ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
                                disabled={loading}
                            >
                                {loading ? <FaSpinner className="animate-spin" /> : 'Save'}
                            </button>
                        </div>
                    </form>
                )}
                <button
                    onClick={onClose}
                    className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-1.5 px-4 rounded-md focus:outline-none focus:shadow-outline mt-4"
                >
                    Close
                </button>
            </div>
        </div>
    );
};

export default ServiceOffers;