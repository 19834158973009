import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import ServiceOffers from './ServiceOffers';
import Layout from '../../components/Layout';
import Loading from '../../components/Loading';

const ServiceDetailsPage = () => {
    const { serviceId } = useParams();
    const navigate = useNavigate();
    const [service, setService] = useState(null);
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetchServiceDetails();
    }, [serviceId]);

    const fetchServiceDetails = async () => {
        try {
            const response = await axios.get(`https://api.discoun3ree.com/api/services/${serviceId}`);
            setService(response.data);
        } catch (error) {
            console.error('Failed to fetch service details', error);
        } finally {
            setLoading(false);
        }
    };

    if (loading) {
        return <Loading />
    }

    if (!service) {
        return <div>Service not found</div>;
    }

    return (
        <Layout>
            <div className="container mx-auto p-4">
                <h1 className="text-2xl font-bold mb-4">{service.name}</h1>
                <div className="mb-4">
                    <label className="block text-gray-700">Description</label>
                    <p>{service.description}</p>
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700">Price</label>
                    <p>{service.price}</p>
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700">Duration</label>
                    <p>{service.duration}</p>
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700">Category</label>
                    <p>{service.category}</p>
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700">Image</label>
                    <div className="relative w-full h-24 overflow-hidden">
                        <img
                            src={service.image_url}
                            alt=""
                            className="absolute inset-0 w-full h-full object-cover rounded-md"
                        />
                    </div>
                </div>
                <button
                    onClick={() => setOpen(true)}
                    className="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                >
                    View Offers
                </button>
                {open && (
                    <ServiceOffers serviceId={service.id} onClose={() => setOpen(false)} />
                )}
                <button
                    onClick={() => navigate('/services')}
                    className="mt-4 bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                >
                    Back to Services
                </button>
            </div>
        </Layout>
    );
};

export default ServiceDetailsPage;
